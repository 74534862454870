import { ListeCenterComponent } from './liste-center/liste-center.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Todo } from './todo.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class TodoService {
 
  private base_url = 'https://liste-backend.onrender.com/'
  //private base_url = 'http://localhost:8080/';
  //private base_url = 'http://localhost:3000/'
  private registerUrl = this.base_url + 'todoitem/registerItem';
  private getListeUrl = this.base_url + 'todoitem/getAllItems';
  private deleteUrl = this.base_url + 'todoitem/';
  private updateStatus = this.base_url + 'todoitem/';
  private getItem = this.base_url + 'todoitem/getItem/';
  private getListeKategorieUrl = this.base_url + 'todoitem/filterkategorie';
  private imageNameURL = this.base_url + 'todoitem/setImageName';
  private getErledigt = this.base_url + 'todoitem/getAllItemsDone';
  private loginURL = this.base_url + 'verify'
  private verifyURL = this.base_url + 'verify'

  
  private liste: Todo[]  = [
    {
      id: 1,
      title: 'Hundi gassi',
      infos: 'wuff wuff',
      kategorie: 'Filme/Serien',
      status: 'offen',
      imgUrl: '',
      datum: '',  
    },
    {
      id: 2,
      title: 'Cipio',
      infos: 'Wein',
      kategorie: 'atz',
      status: 'offen',
      imgUrl: '',
      datum: '',   
    }
  ];

  private testListe: Todo[];

  constructor(private http: HttpClient, private router: Router) { }

  registerTodo(todo: Todo) {
    this.http.post(this.registerUrl, todo).subscribe(data => {

     }, error => {
    
    });

  }
  

  getAllTodos() {
   
    return this.http.get(this.getListeUrl);
  }


  getAllTodosKategorie(kategorie) {
    return this.http.post(this.getListeKategorieUrl, kategorie);
  }

  getAllDoneTodos() {
    return this.http.get(this.getErledigt);
  }

 postImageName(name: string, id:number) {
    const url = "https://www.wushh.bplaced.net/upload/uploads/" + name;
    this.http.put(this.imageNameURL, {url, id}).subscribe();
  }


  deleteEintrag(id){
    return this.http.delete(this.deleteUrl + id).subscribe();
  
  }

  setStatusToDone(id: number) {
    return this.http.put(this.updateStatus + id, null).subscribe();
  }
  


  getTodo(id: number) {
    //return this.liste
    console.log(this.getItem + id)
    return this.http.get(this.getItem + id);
  }


  login(password: string) {
   this.http.post<any>(this.loginURL, {password}).subscribe(
      res => {
        localStorage.setItem('token', res.token)
       this.router.navigate(['/liste']);
      },
      err => {
        console.log(err);
        return false;
      }
    )
  }

  loggedIn() {
    return !!localStorage.getItem('token');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  verifyToken() {
    return this.http.get(this.verifyURL);
  }
}
