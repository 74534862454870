import { TokenInterceptorService } from './token-interceptor.service';
import { AuthGuard } from './auth.guard';
import { EventEmitterService } from './event-emitter.service';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { IonicModule, IonicRouteStrategy} from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {NgxImageCompressService} from 'ngx-image-compress';
import { CommonModule } from '@angular/common';
import { FormsModule, FormControl } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,IonicModule, IonicModule.forRoot(), AppRoutingModule, CommonModule, SharedModule, HttpClientModule],
  providers: [
    NgxImageCompressService,
    EventEmitterService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },

    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {provide : LocationStrategy , useClass: HashLocationStrategy}
   
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
