import { AuthGuard } from './auth.guard';

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, CanActivate } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BrowserModule } from '@angular/platform-browser';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'liste',
    children: [
      {path: '', loadChildren: () => import('./liste/liste.module').then( m => m.ListePageModule)},
      {path: './:id', loadChildren: () => import('./liste/todo-detail/todo-detail.module').then( m => m.TodoDetailPageModule), }
    ],
    canActivate: [AuthGuard]
  },

  {
    path: 'archiv',
    children: [
      {path: '', loadChildren: () => import('./archiv/archiv.module').then( m => m.ArchivPageModule)},
      {path: './:id', loadChildren: () => import('./archiv/archiv-detail/archiv-detail.module').then( m => m.ArchivDetailPageModule), }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },


];

@NgModule({
  imports: [
    BrowserModule, CommonModule, IonicModule, RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
