import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

 
  invokeFirstComponentFunction = new EventEmitter();
  invokeDeleteMethod =  new EventEmitter();  
  invokeDeleteMethod2 =  new EventEmitter();
  invokeDeleteMethod3 =  new EventEmitter();
  invokeErledigtMethod =  new EventEmitter();   
  invokeErledigtMethod2 =  new EventEmitter();   

  subsVar: Subscription;
  subsVarDelete: Subscription;  
  subsVarDelete2: Subscription;  
  subsVarDelete3: Subscription; 
  subsVarArchiv: Subscription;  
  subsVarArchiv2: Subscription;  


    
  constructor() { }    
    
  onFirstComponentButtonClick() {    
    this.invokeFirstComponentFunction.emit();    
  }
  
  deleteEvent() {
    this.invokeDeleteMethod.emit();    
  }

  deleteEvent2() {
    this.invokeDeleteMethod2.emit();    
  }

  deleteEvent3() {
    this.invokeDeleteMethod3.emit();
  }



  addArchivItem() {
    console.log('event service');      
    this.invokeErledigtMethod.emit();  
    
  }

  addArchivItem2() {
    console.log('event service');      
    this.invokeErledigtMethod2.emit();  
    
  }

}
