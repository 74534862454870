import { TodoService } from './liste/todo.service';
import { AuthGuard } from './auth.guard';
import { Injectable, Injector } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private injector: Injector, private TodoService: TodoService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    // check if there is token
    if (token != null) {
      const tokenizedReq = req.clone(
        
        { headers:req.headers.set('authorization', this.TodoService.getToken())
      }
      );
      return next.handle(tokenizedReq);
    } else {
      return next.handle(req);
    }
  }
}