import { TodoService } from './liste/todo.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor (private TodoService: TodoService, private router: Router){}

  canActivate(): boolean {
    if(this.TodoService.loggedIn()) {
      return true;
    } else {
      this.router.navigate(['/login']);
    }

  }

  
}
