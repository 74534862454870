import { TodoService } from './liste/todo.service';
import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(private router: Router, private TodoService: TodoService

  ) {
    

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
      this.TodoService.verifyToken().subscribe(
        res => { },
        err => {

          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
    
              localStorage.removeItem('token');
              this.router.navigate(['/login']);         
                                  }
          }
        } 
      )
      }

    
    })

    this.initializeApp();
    }
  

  initializeApp() {

  }

  isHomeRoute() {
    return this.router.url === '/login';

  }
}
